
import React, { useState, useEffect, Fragment } from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import axios from 'axios'
import { Link } from 'react-router-dom'
import { urlB64ToUint8Array } from '../components/ServiceWorker'
import qs from 'qs'

import { fetchArticleAPI } from '../../store/article'
import { getImageSize, getImage, getCanonical } from '../components/ui/articleutils'
import { isBrowser, setCookie } from '../components/ui/utils'
import { formatAgo } from '../components/ui/dateutils'
import { ImageOrNoImage } from 'ion-image'
import Popup from 'ion-popup'
import popupLogo from '../static/general/glam-popup.svg'
import NoImage from '../static/no-image.png'
import NotificationImg from '../static/general/glam-notifications-page.jpg'
import StyledLink from '../components/StyledLink'
import { db } from '../db'

const NotifiedArticles = ({ uuids }) => {
  const [articles, setArticles] = useState([])
  useEffect(() => {
    const fetchArticles = async () => {
      const articles = await Promise.all(uuids.map(uuid => fetchArticleAPI(uuid)))
      setArticles(articles)
    }
    fetchArticles()
  }, [uuids])
  return (
    <div>
      <div className='notifications-articles'>
        {articles.map((article, index) => (
          <Fragment key={index}>
            <article key={article.uuid}>
              <StyledLink keyProp={article.uuid} to={'/' + getCanonical(article)} display='grid' gap={20} gridTemplateColumns='auto 80px'>
                <div>
                  <h6>{article.headline}</h6>
                  <p>By {article.author} | Published {formatAgo(article.published, true)}</p>
                </div>
                <div>
                  <ImageOrNoImage image={getImage(article)} width={getImageSize('push')} alt={article.headline} shape='square' noImage={NoImage} />
                </div>
              </StyledLink>
            </article>
          </Fragment>
        ))}
      </div>
    </div>
  )
}

function subscribeConcept (registration, setSubscription, setHelpPopupVisible) {
  if (registration) {
    registration.pushManager.subscribe({
      userVisibleOnly: true,
      applicationServerKey: urlB64ToUint8Array(process.env.RAZZLE_SERVERKEY)
    })
      .then(function (subscription) {
        console.log('User IS now subscribed.')
        setSubscription(subscription)
      })
      .catch(function (err) {
        console.log('Failed to subscribe the user: ', err)
        setHelpPopupVisible(true)
        setSubscription(false)
      })
  }
}

const TopicItem = ({ topic, subscription, subscribed, toggleSubscribed, registration, setSubscription, setHelpPopupVisible }) => {
  const handleOnChange = () => {
    if (navigator) {
      navigator.permissions.query({
        name: 'notifications'
      }).then((result) => {
        if (result.state === 'denied' || result.state === 'prompt') {
          setHelpPopupVisible(true)
        } else {
          if (!subscription) {
            subscribeConcept(registration, setSubscription, setHelpPopupVisible)
          } else {
            const data = {
              topic: topic.topic,
              titleKey: process.env.RAZZLE_TITLE_KEY,
              subscription: JSON.stringify(subscription)
            }
            axios.post(process.env.RAZZLE_PUSH_SERVICE + (subscribed ? '/unsubscribe' : '/subscribe'), qs.stringify(data), {
              headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
              }
            })
              .then(res => toggleSubscribed(topic))
              .catch(error => {
                console.log(subscribed ? 'Unsubscribe' : 'Subscribe', 'Error:', error.message)
                return false
              })
          }
        }
      })
    }
  }
  return (
    <div>
      <label htmlFor={topic.name} position='relative'>
        {topic.name === 'General' ? 'Recent Stories' : topic.name}
        <div className='switch'>
          <input id={topic.name} type='checkbox' onChange={handleOnChange} checked={subscribed} />
          <span className='slider' />
        </div>
      </label>
    </div>
  )
}

const PrivatePushNotificationsManagementPage = props => {
  const [topicsDict, setTopicsDict] = useState([])
  const [subscribed, setSubscribed] = useState(new Map())
  const [pushedArticles, setPushedArticles] = useState([])
  const [subscription, setSubscription] = useState(false)
  const [registration, setRegistration] = useState(false)
  const [helpPopupVisible, setHelpPopupVisible] = useState(false)
  const generalTopic = { topic: 'General', type: 'Notification', name: 'General' }

  useEffect(() => {
    if (navigator.serviceWorker) {
      navigator.serviceWorker.getRegistration()
        .then(reg => {
          setRegistration(reg)
        })
    }
  }, [])

  useEffect(() => {
    if (registration && registration.pushManager) {
      registration.pushManager.getSubscription()
        .then(sub => {
          setSubscription(sub)
        })
    }
  }, [registration])

  useEffect(() => {
    db.messages.reverse().toArray().then(articles => setPushedArticles(articles.map(article => article.uuid)))
    setCookie('NotificationsLastViewed', new Date().toISOString(), { days: 30 })
  }, [])

  useEffect(() => {
    const data = qs.stringify({
      titleKey: process.env.RAZZLE_TITLE_KEY,
      endpoint: (subscription && subscription.endpoint) || ''
    })
    axios.post(process.env.RAZZLE_PUSH_SERVICE + '/topics', data, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    })
      .then(res => {
        const toggleSliders = new Map()
        res.data.map(topic => {
          toggleSliders.set(topic.topic, true)
          return topic
        })
        setSubscribed(toggleSliders)
        const dict = res.data.reduce((previousTopic, currentTopic, index) => {
          const firstType = currentTopic.type
          if (previousTopic[firstType]) {
            previousTopic[firstType].push(currentTopic)
          } else {
            previousTopic[firstType] = [currentTopic]
          }
          return previousTopic
        }, {})
        return setTopicsDict(dict)
      })
  }, [subscription])

  const handleUnfollowAll = async (topics, subscription) => {
    const newSubscribed = new Map(subscribed)
    await Promise.all(topics.map(async (topic) => {
      const data = {
        topic: topic.topic,
        titleKey: process.env.RAZZLE_TITLE_KEY,
        subscription: JSON.stringify(subscription)
      }
      await axios.post(process.env.RAZZLE_PUSH_SERVICE + '/unsubscribe', qs.stringify(data), {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
        .then(res => {
          return newSubscribed.set(topic.topic, false)
        })
        .catch(error => {
          console.log('Unsubscribe Error:', error.message)
          return false
        })
    }))
    return setSubscribed(newSubscribed)
  }

  function toggleSubscribed (topic) {
    const newSubscribed = new Map(subscribed)
    newSubscribed.set(topic.topic, !(newSubscribed.get(topic.topic)))
    setSubscribed(newSubscribed)
  }

  return (
    <>
      {helpPopupVisible &&
        <Popup visible>
          <div className='push-notification'>
            <div>
              <img src={popupLogo} alt={process.env.RAZZLE_SITE_NAME} />
            </div>
            <div color='grey'>
              <p>Small problem..</p>
              <p>You have chosen to block notifications.</p>
              <p>To follow stories requires you to enable notifications in your browser settings.</p>
              <div display='flex' justifycontent='flex-end'>
                <Link onClick={() => setHelpPopupVisible(false)} to='/help'>Help</Link>
                <button onClick={() => setHelpPopupVisible(false)}>OK</button>
              </div>
            </div>
          </div>
        </Popup>}
      <div className='wrapper'>
        <h1>My Notifications</h1>
        <img src={NotificationImg} alt='' />
        <NotifiedArticles uuids={pushedArticles} />
        <div>
          <div className='notifications-section-h2'>
            <h2>Notification Followed</h2>
          </div>
          <div className='notifications-list'>
            <TopicItem key={generalTopic.topic} topic={generalTopic} registration={registration} subscription={subscription} setSubscription={setSubscription} subscribed={subscribed && !!subscribed.get(generalTopic.topic)} setHelpPopupVisible={setHelpPopupVisible} toggleSubscribed={() => toggleSubscribed(generalTopic)} />
          </div>
        </div>
        {topicsDict && Object.keys(topicsDict).sort().map((topicType, index) => {
          return (
            <Fragment key={topicType}>
              {topicType !== 'Notification' &&
                <div>
                  <div className='notifications-section-h2'>
                    <h2>{topicType.replace(/y$/, 'ie')}s Followed</h2>
                    <button className='follow-me-btn' onClick={() => { handleUnfollowAll(topicsDict[topicType], subscription) }}>Unfollow All {topicType.replace(/y$/, 'ie')}s</button>
                  </div>
                  <div className='notifications-list'>
                    {topicsDict[topicType].map((topic) =>
                      <TopicItem key={topic.topic} topic={topic} registration={registration} subscription={subscription} setSubscription={setSubscription} subscribed={subscribed && !!subscribed.get(topic.topic)} setHelpPopupVisible={setHelpPopupVisible} toggleSubscribed={() => toggleSubscribed(topic)} />
                    )}
                  </div>
                </div>}
            </Fragment>
          )
        })}
        {/* If no subscriptions or you are only subscribed to General */}
        {topicsDict && (Object.keys(topicsDict).filter(item => item !== 'Notification').length < 1) &&
          <div className='static-page'>
            <div>
              <h2>You are not following any authors or topics.</h2>
              <p>If you would like to be notified when a new article is published by your preferred author, or on your topic of interest, then you can do this by following that author or topic.</p>
              <p>On an article, click on the author's link, or on the topic at the bottom of the article, and from there, click to follow.</p>
            </div>
          </div>}
      </div>
    </>
  )
}

const PushNotificationsManagementPage = (props) => {
  if (isBrowser) {
    return <PrivatePushNotificationsManagementPage {...props} />
  } else {
    return null
  }
}

const mapStateToProps = (state) => ({})
const mapDispatchToProps = (dispatch) => bindActionCreators({ fetchArticleAPI }, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationsManagementPage)
