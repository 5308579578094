import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'
import { Articles } from 'ion-article-cmp'
import HomePageMeta from '../../components/HomePageMeta'
import Ad from '../../components/Ad'
import WingBanners from '../../components/WingBanners'
import FORMATS from '../../components/AdFormats'
import { MobileElse } from 'ion-media'
import NoImage from '../../static/no-image.png'
import HomePageTemplate from '../../templates/Articles/HomePageHighlight'
import SiteSidebar from '../../components/Sidebar'
import logo from '../../static/general/glamour.svg'
import MagazineCover from '../../components/MagazineCover'

export class HomePage extends Component {
  constructor (props) {
    super(props)
    // Don't call this.setState() here!
    this.state = { isEmpty: true, is600: false }
  }

  render () {
    return (
      <>
        <WingBanners useSmall={this.props.useSmall} isConnected={this.props.isConnected} location={this.props.location} onSlotRenderEndedLeft={this.props.onSlotRenderEndedLeft} onSlotRenderEndedRight={this.props.onSlotRenderEndedRight} />
        <Helmet title={process.env.RAZZLE_SITE_TITLE}>
          <meta property='og:url' content={process.env.RAZZLE_SITE_URL} />
          <meta name='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta itemProp='description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta property='og:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='twitter:site' content='@GLAMOUR_sa' />
          <meta name='twitter:creator' content='@GLAMOUR_sa' />
          <meta name='twitter:description' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='twitter:title' content={process.env.RAZZLE_SITE_DESCRIPTION} />
          <meta name='twitter:card' content='summary_large_image' />
          <meta name='twitter:image:src' content={logo} />
        </Helmet>
        <HomePageMeta {...this.props} />
        <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-1' targeting={{ leaderboard: '1' }} collapseEmptyDiv className='advert-leaderboard-1' {...FORMATS.leaderboard} />
        <div className='wrapper article-listing' ref={this.setStickyRef}>
          <Articles {...this.props} component={HomePageTemplate} section='all-content-feed' count={15} pageSize={14} noImage={NoImage} withMore />
          <SiteSidebar {...this.props} section='homepage' sticky={this.state.sticky}>
            <MobileElse>
              <MagazineCover />
            </MobileElse>
          </SiteSidebar>
        </div>
        <MobileElse>
          <Ad isConnected={this.props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-3' {...FORMATS.leaderboard} />
        </MobileElse>
      </>
    )
  }
}

const mapStateToProps = (state) => ({ articles: state.articles })

export default connect(mapStateToProps)(HomePage)
