import React from 'react'
import { Link } from 'react-router-dom'
import { Mobile, MobileElse } from 'ion-media'
import { ImageOrNoImage } from 'ion-image'

const ArticleCard = ({ article, noImage, lazy }) => (
  <article key={article.contentKey} aria-label='article'>
    <div className='article-list-img'>
      <Link to={'/' + article.getCanonicalUri()}>
        <Mobile>
          <ImageOrNoImage shape='square' width={450} image={article.image} alt={article.headline} noImage={noImage} />
        </Mobile>
        <MobileElse>
          <ImageOrNoImage shape='square' width={670} image={article.image} alt={article.headline} noImage={noImage} />
        </MobileElse>
      </Link>
    </div>
    <div className='content'>
      <p><Link to={'/' + article.getSectionId()}>{article.sectionLabel}</Link></p>
      <h4><Link to={'/' + article.getCanonicalUri()}>{article.headline}</Link></h4>
      {(article.sponsoredIndicator === 'SPONSORED' || article.sponsoredIndicator === 'SPONSORED_EXPIRED') &&
        <small className='sponsored'>Sponsored</small>}
      {(article.sponsoredIndicator === 'NATIVE' || article.sponsoredIndicator === 'NATIVE_EXPIRED') &&
        <small className='native'>Partnered</small>}
    </div>
  </article>
)

export default ArticleCard
