import React, { Component } from 'react'
import Helmet from 'react-helmet'
import { Sticky } from 'semantic-ui-react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import 'semantic-ui-css/components/sticky.min.css'
import Ad from './Ad'
import FORMATS from './AdFormats'
import { Mobile, Desktop, MobileElse, DesktopTablet } from 'ion-media'
import { Search, LinkedIn, Facebook, Twitter, Instagram, Youtube, Pinterest, TikTok } from 'ion-icon'
import NoImage from '../static/no-image.png'
import { subscribeNewsletter, updateForm, reinitialize } from '../../store/subscribe'
import { Dimmer, Loader, Segment } from 'semantic-ui-react/dist/commonjs'
import MostReadArticles from './MostReadArticles'
import NotificationsBell from './NotificationsBell'

export class Sidebar extends Component {
  state = {} // eslint-disable-line no-undef

  constructor (props) {
    super(props)
    this.state = { sticky: undefined, hasMounted: false }
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.sticky = null
    this.setStickyRef = element => this.setState({ sticky: element })
  }

  componentDidMount () {
    this.setState({ hasMounted: true })
  }

  handleChange (event) {
    const newState = {}
    newState[event.target.name] = event.target.value
    this.props.updateForm(newState)
  }

  validateEmail (email) {
    const re = /^[^.@][^@]+@{1}[^@]+\.[^@]+[^.@]$/i
    return email.match(re)
  }

  validateName (name) {
    const re = /^[`a-zA-ZàèìòùÀÈÌÒÙáéíóúýÁÉÍÓÚÝâêîôûÂÊÎÔÛãñõÃÑÕäëïöüÿÄËÏÖÜŸçÇßØøÅåÆæœ' -]{2,45}$/i
    return name.match(re)
  }

  handleSubmit (event) {
    event.preventDefault()
    if (!this.validateEmail(this.props.email)) {
      this.props.updateForm({ error: 'Please enter a valid email address' })
      return false
    }
    if (!this.validateName(this.props.firstName) || !this.validateName(this.props.lastName)) {
      this.props.updateForm({ error: 'Please enter valid first and last names' })
      return false
    }
    this.props.subscribeNewsletter({ email: this.props.email, firstName: this.props.firstName, lastName: this.props.lastName })
    return true
  }

  render () {
    const props = this.props
    const showForm = !props.hasSubmitted && !props.isSubmitting
    const submitting = props.isSubmitting
    const showThankYou = props.hasSubmitted
    return (
      <aside className='sidebar' ref={this.setStickyRef}>
        <Mobile>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='leaderboard-3' targeting={{ leaderboard: '3' }} collapseEmptyDiv className='advert-leaderboard-3' {...FORMATS.leaderboard} />
        </Mobile>
        <div className='wrapper'>
          {this.state.hasMounted &&
            <DesktopTablet>
              <div className='follow-us'>
                <span>Follow us on</span>
                <nav>
                  <ul>
                    <li>
                      <a href={process.env.RAZZLE_SITE_LINKEDIN} target='_blank' rel='noopener noreferrer' title='Follow Glamour on LinkedIn'>
                        <LinkedIn width='14' height='14' />
                      </a>
                    </li>
                    <li>
                      <a href={process.env.RAZZLE_SITE_FACEBOOK} target='_blank' rel='noopener noreferrer' title='Like Glamour on Facebook'>
                        <Facebook width='14' height='14' />
                      </a>
                    </li>
                    <li>
                      <a href={process.env.RAZZLE_SITE_INSTAGRAM} target='_blank' rel='noopener noreferrer' title='Follow Glamour on Instagram'>
                        <Instagram width='14' height='14' />
                      </a>
                    </li>
                    <li>
                      <a href={process.env.RAZZLE_SITE_TWITTER} target='_blank' rel='noopener noreferrer' title='Follow Glamour on Twitter'>
                        <Twitter width='14' height='14' fill='white' />
                      </a>
                    </li>
                    <li>
                      <a href={process.env.RAZZLE_SITE_YOUTUBE} target='_blank' rel='noopener noreferrer' title='Subscribe to Glamour on Youtube'>
                        <Youtube width='14' height='14' />
                      </a>
                    </li>
                    <li>
                      <a href={process.env.RAZZLE_SITE_TIKTOK} target='_blank' rel='noopener noreferrer' title='Follow Glamour on TikTok'>
                        <TikTok width='14' height='14' />
                      </a>
                    </li>
                    <li>
                      <a href={process.env.RAZZLE_SITE_PINTEREST} target='_blank' rel='noopener noreferrer' title='Follow Glamour on Pinterest'>
                        <Pinterest width='14' height='14' />
                      </a>
                    </li>
                  </ul>
                </nav>
              </div>
            </DesktopTablet>}
        </div>
        <div className='sidebar-search'>
          <form action='/search' method='get'>
            <input type='search' placeholder='Search Glamour' name='q' id='q' />
            <button>
              <Search width='18' height='18' />
            </button>
          </form>
        </div>
        <NotificationsBell />
        {props.children}
        {this.state.hasMounted &&
          <MobileElse>
            <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='mpu-1' targeting={{ mpu: '1' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu} />
          </MobileElse>}
        <div className='newsletter-subscribe'>
          <h5>Glamour NEWSLETTER</h5>
          {showForm &&
            <>
              <form name='item-form' id='item-form' onSubmit={this.handleSubmit}>
                {props.error &&
                  <small style={{ color: 'red' }}>{this.props.error ? this.props.error : 'An error occured, please try again'}</small>}
                <input type='text' name='firstName' placeholder='First Name' id='firstName' value={this.props.firstName} onChange={this.handleChange} required />
                <input type='text' name='lastName' placeholder='Last Name' id='lastName' value={this.props.lastName} onChange={this.handleChange} />
                <input type='email' name='email' placeholder='Email Address' id='email' value={this.props.email} onChange={this.handleChange} required />
                <button type='submit'>Subscribe</button>
              </form>
            </>}
          {submitting &&
            <>
              <Helmet title='Article' />
              <Segment>
                <Dimmer active>
                  <Loader indeterminate size='large'>Submitting subscription.....</Loader>
                </Dimmer>
              </Segment>
            </>}
          {showThankYou &&
            <>
              <Helmet title='Article' />
              <h3>Thank you for Subscribing!</h3>
              <p>We have sent a confirmation email to the email address that you provided. Click on the link in the email to confirm your subscription to our newsletter.</p>
            </>}
        </div>
        {this.state.hasMounted &&
          <MobileElse>
            <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='mpu-2' targeting={{ mpu: '2' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.mpu} />
          </MobileElse>}
        <div className='sidebar-articles'>
          {process.env.RAZZLE_SHOW_MOST_POPULAR_WIDGET &&
            <MostReadArticles section={props.section} className='side-articles' noImage={NoImage} contentKey={this.props.contentKey} />}
        </div>
        <Mobile>
          <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='halfpage-1' targeting={{ halfpage: '1' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.halfpage} />
        </Mobile>
        {this.state.hasMounted &&
          <Desktop>
            <Sticky context={this.state.sticky} offset={102}>
              <Ad isConnected={props.isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/${props.section}`} slotId='halfpage-1' targeting={{ halfpage: '1' }} collapseEmptyDiv className='advert-sidebar' {...FORMATS.halfpage} />
            </Sticky>
          </Desktop>}
      </aside>
    )
  }
}
const mapStateToProps = (state) => (Object.assign({}, state.subscribe))
const mapDispatchToProps = (dispatch) => bindActionCreators({
  subscribeNewsletter,
  updateForm,
  reinitialize
}, dispatch)

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar)
