import React, { Fragment } from 'react'
import Ad from '../../components/Ad'
import FORMATS from '../../components/AdFormats'
import ArticleCard from '../../components/ArticleCard'
import { Desktop, Mobile, MobileElse } from 'ion-media'
import MagazineCover from '../../components/MagazineCover'
import useHasMounted from '../../components/useHasMounted'

const Default = ({ articles, section, noImage, isConnected, moreButton }) => {
  const hasMounted = useHasMounted()
  return (
    <>
      {articles.map((article, index) => (
        <Fragment key={article.contentKey}>
          {hasMounted &&
            <Desktop>
              {((index === 15) || (((index - 15) % 14 === 0) && (index > 16))) && (
                <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} targeting={{ leaderboard: 'vm' }} className='advert-leaderboard-vm' {...FORMATS.leaderboard2} />
              )}
            </Desktop>}
          {index <= 3 && (
            <ArticleCard article={article} noImage={noImage} />
          )}
          {index > 3 && (
            <ArticleCard article={article} noImage={noImage} />
          )}
          {hasMounted &&
            <MobileElse>
              {index === 4 && (
                <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId='leaderboard-2' targeting={{ leaderboard: '2' }} collapseEmptyDiv className='advert-leaderboard-2' {...FORMATS.leaderboard2} />
              )}
            </MobileElse>}
          <Mobile>
            {(index === 2) && (
              <MagazineCover />
            )}
          </Mobile>
          {Number.isInteger((index + 5) / 4) && (
            <Mobile>
              <article className='advert'>
                <Ad isConnected={isConnected} path={`/${process.env.RAZZLE_DFP_CODE}/homepage`} slotId={'repeatMpu-' + index} targeting={{ mpu: index < 14 ? ((index + 1) / 4) : 'vm' }} collapseEmptyDiv className='align-all' adNotice {...FORMATS.mpu} />
              </article>
            </Mobile>
          )}
        </Fragment>
      ))}
      {moreButton}
    </>
  )
}

export default Default
