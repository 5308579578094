import React from 'react'
import Helmet from 'react-helmet'

const HomePageMeta = (props) => {
  const schema = {
    '@context': 'https://schema.org',
    '@graph': [{
      '@type': 'Organization',
      name: process.env.RAZZLE_SCHEMA_SITE_NAME,
      url: process.env.RAZZLE_SITE_URL,
      logo: process.env.RAZZLE_SITE_URL + '/logo.jpg',
      description: process.env.RAZZLE_SITE_DESCRIPTION,
      sameAs: [
        process.env.RAZZLE_SITE_FACEBOOK,
        process.env.RAZZLE_SITE_TWITTER,
        process.env.RAZZLE_SITE_INSTAGRAM,
        process.env.RAZZLE_SITE_YOUTUBE,
        process.env.RAZZLE_SITE_LINKEDIN
      ]
    }, {
      '@type': 'WebSite',
      name: process.env.RAZZLE_SCHEMA_SITE_NAME,
      url: process.env.RAZZLE_SITE_URL,
      headline: process.env.RAZZLE_SITE_TITLE,
      potentialAction: {
        '@type': 'SearchAction',
        target: process.env.RAZZLE_SITE_URL + '/search?q={search_term_string}',
        'query-input': 'required name=search_term_string'
      }
    }]
  }
  return (
    <Helmet>
      {/* inline script elements */}
      <script type='application/ld+json'>
        {`${JSON.stringify(schema)}`}
      </script>
    </Helmet>
  )
}
export default HomePageMeta
